export default {
  default: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/default.vue").then(m => m.default || m),
  "double-column-layout": () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/doubleColumnLayout.vue").then(m => m.default || m),
  fund: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/fund.vue").then(m => m.default || m),
  "fund-footer": () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/fundFooter.vue").then(m => m.default || m),
  login: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/login.vue").then(m => m.default || m),
  ockto: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/ockto.vue").then(m => m.default || m),
  "ockto-steps": () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/ocktoSteps.vue").then(m => m.default || m),
  "privacy-layout": () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/privacyLayout.vue").then(m => m.default || m),
  sidebar: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/sidebar.vue").then(m => m.default || m),
  sign: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/sign.vue").then(m => m.default || m),
  "single-column": () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/layouts/singleColumn.vue").then(m => m.default || m)
}