import { default as _91choice_93WF7KMJrPW5Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as indexkb7RbGInecMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as _91hash_93rg3GBpAFOUMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/einde-looptijd-lening/[hash].vue?macro=true";
import { default as index5O9OQP7JzWMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexrToej8K5v4Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/index.vue?macro=true";
import { default as index5sP6hmbn3FMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoordhWbjpraNsiMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetenFz2MNa1Ap2Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indextMul09RSiXMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as index4dVdFhSaLFMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexn0ifD5YAQQMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexl39C4keQjyMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexrOIkFcHNyzMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexMICAIiDjKjMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexwAzl7Gbh8DMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexyOyu7qRo02Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as index8CrLEBZxkwMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexsAhX8ETxEBMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexP0PDgTzqJ3Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as index0EH4Pb7NIhMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as index03BsLFTAmiMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexjlKinhr6FGMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexts1XOtF4oBMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as index01EZGJFM1JMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as index2osWsEY6BHMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexjFMhbU9vZ8Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexnPoLeeD517Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorA34s9Dy9sPMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexOZgz5TcywyMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodewgpz0ebEcPMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesFu61LJ9hP5Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_933KaLZfVfeHMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_930jMF1Q3UVoMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraagswWH6IFPy4Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93nV0yUBajuUMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_93F5exsKXiPIMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93a140bsFunLMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as indexiUU1go5P8GMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91fundKey_93aV5IX3RAXoMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagOEgpMnSe5zMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexHuRMS4W8o1Meta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_93bMAb3tgq0mMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexqxVzW8XdBoMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93deEVGDK5znMeta } from "/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93WF7KMJrPW5Meta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93WF7KMJrPW5Meta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93WF7KMJrPW5Meta || {},
    alias: _91choice_93WF7KMJrPW5Meta?.alias || [],
    redirect: _91choice_93WF7KMJrPW5Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: indexkb7RbGInecMeta?.name ?? "aanbod",
    path: indexkb7RbGInecMeta?.path ?? "/aanbod",
    meta: indexkb7RbGInecMeta || {},
    alias: indexkb7RbGInecMeta?.alias || [],
    redirect: indexkb7RbGInecMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93rg3GBpAFOUMeta?.name ?? "einde-looptijd-lening-hash",
    path: _91hash_93rg3GBpAFOUMeta?.path ?? "/einde-looptijd-lening/:hash()",
    meta: _91hash_93rg3GBpAFOUMeta || {},
    alias: _91hash_93rg3GBpAFOUMeta?.alias || [],
    redirect: _91hash_93rg3GBpAFOUMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/einde-looptijd-lening/[hash].vue").then(m => m.default || m)
  },
  {
    name: index5O9OQP7JzWMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: index5O9OQP7JzWMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: index5O9OQP7JzWMeta || {},
    alias: index5O9OQP7JzWMeta?.alias || [],
    redirect: index5O9OQP7JzWMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrToej8K5v4Meta?.name ?? "index",
    path: indexrToej8K5v4Meta?.path ?? "/",
    meta: indexrToej8K5v4Meta || {},
    alias: indexrToej8K5v4Meta?.alias || [],
    redirect: indexrToej8K5v4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index5sP6hmbn3FMeta?.name ?? "login",
    path: index5sP6hmbn3FMeta?.path ?? "/login",
    meta: index5sP6hmbn3FMeta || {},
    alias: index5sP6hmbn3FMeta?.alias || [],
    redirect: index5sP6hmbn3FMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoordhWbjpraNsiMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoordhWbjpraNsiMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoordhWbjpraNsiMeta || {},
    alias: reset_45wachtwoordhWbjpraNsiMeta?.alias || [],
    redirect: reset_45wachtwoordhWbjpraNsiMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenFz2MNa1Ap2Meta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetenFz2MNa1Ap2Meta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenFz2MNa1Ap2Meta || {},
    alias: wachtwoord_45vergetenFz2MNa1Ap2Meta?.alias || [],
    redirect: wachtwoord_45vergetenFz2MNa1Ap2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indextMul09RSiXMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indextMul09RSiXMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indextMul09RSiXMeta || {},
    alias: indextMul09RSiXMeta?.alias || [],
    redirect: indextMul09RSiXMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: index4dVdFhSaLFMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: index4dVdFhSaLFMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: index4dVdFhSaLFMeta || {},
    alias: index4dVdFhSaLFMeta?.alias || [],
    redirect: index4dVdFhSaLFMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexn0ifD5YAQQMeta?.name ?? "mijn-aanvragen",
    path: indexn0ifD5YAQQMeta?.path ?? "/mijn-aanvragen",
    meta: indexn0ifD5YAQQMeta || {},
    alias: indexn0ifD5YAQQMeta?.alias || [],
    redirect: indexn0ifD5YAQQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexl39C4keQjyMeta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexl39C4keQjyMeta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexl39C4keQjyMeta || {},
    alias: indexl39C4keQjyMeta?.alias || [],
    redirect: indexl39C4keQjyMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexrOIkFcHNyzMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexrOIkFcHNyzMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexrOIkFcHNyzMeta || {},
    alias: indexrOIkFcHNyzMeta?.alias || [],
    redirect: indexrOIkFcHNyzMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexMICAIiDjKjMeta?.name ?? "mijn-organisaties",
    path: indexMICAIiDjKjMeta?.path ?? "/mijn-organisaties",
    meta: indexMICAIiDjKjMeta || {},
    alias: indexMICAIiDjKjMeta?.alias || [],
    redirect: indexMICAIiDjKjMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexwAzl7Gbh8DMeta?.name ?? "mijn-partnerplan-token-choice",
    path: indexwAzl7Gbh8DMeta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexwAzl7Gbh8DMeta || {},
    alias: indexwAzl7Gbh8DMeta?.alias || [],
    redirect: indexwAzl7Gbh8DMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyOyu7qRo02Meta?.name ?? "mijn-partnerplan",
    path: indexyOyu7qRo02Meta?.path ?? "/mijn-partnerplan",
    meta: indexyOyu7qRo02Meta || {},
    alias: indexyOyu7qRo02Meta?.alias || [],
    redirect: indexyOyu7qRo02Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: index8CrLEBZxkwMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: index8CrLEBZxkwMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: index8CrLEBZxkwMeta || {},
    alias: index8CrLEBZxkwMeta?.alias || [],
    redirect: index8CrLEBZxkwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexsAhX8ETxEBMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexsAhX8ETxEBMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexsAhX8ETxEBMeta || {},
    alias: indexsAhX8ETxEBMeta?.alias || [],
    redirect: indexsAhX8ETxEBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexP0PDgTzqJ3Meta?.name ?? "mijn-profiel",
    path: indexP0PDgTzqJ3Meta?.path ?? "/mijn-profiel",
    meta: indexP0PDgTzqJ3Meta || {},
    alias: indexP0PDgTzqJ3Meta?.alias || [],
    redirect: indexP0PDgTzqJ3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: index0EH4Pb7NIhMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: index0EH4Pb7NIhMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: index0EH4Pb7NIhMeta || {},
    alias: index0EH4Pb7NIhMeta?.alias || [],
    redirect: index0EH4Pb7NIhMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: index03BsLFTAmiMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: index03BsLFTAmiMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: index03BsLFTAmiMeta || {},
    alias: index03BsLFTAmiMeta?.alias || [],
    redirect: index03BsLFTAmiMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexjlKinhr6FGMeta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexjlKinhr6FGMeta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexjlKinhr6FGMeta || {},
    alias: indexjlKinhr6FGMeta?.alias || [],
    redirect: indexjlKinhr6FGMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexts1XOtF4oBMeta?.name ?? "mogelijk-fonds-id",
    path: indexts1XOtF4oBMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexts1XOtF4oBMeta || {},
    alias: indexts1XOtF4oBMeta?.alias || [],
    redirect: indexts1XOtF4oBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index01EZGJFM1JMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: index01EZGJFM1JMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: index01EZGJFM1JMeta || {},
    alias: index01EZGJFM1JMeta?.alias || [],
    redirect: index01EZGJFM1JMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: index2osWsEY6BHMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: index2osWsEY6BHMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: index2osWsEY6BHMeta || {},
    alias: index2osWsEY6BHMeta?.alias || [],
    redirect: index2osWsEY6BHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexjFMhbU9vZ8Meta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexjFMhbU9vZ8Meta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexjFMhbU9vZ8Meta || {},
    alias: indexjFMhbU9vZ8Meta?.alias || [],
    redirect: indexjFMhbU9vZ8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexnPoLeeD517Meta?.name ?? "mogelijk-fonds",
    path: indexnPoLeeD517Meta?.path ?? "/mogelijk-fonds",
    meta: indexnPoLeeD517Meta || {},
    alias: indexnPoLeeD517Meta?.alias || [],
    redirect: indexnPoLeeD517Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorA34s9Dy9sPMeta?.name ?? "ockto-hash-error",
    path: errorA34s9Dy9sPMeta?.path ?? "/ockto/:hash()/error",
    meta: errorA34s9Dy9sPMeta || {},
    alias: errorA34s9Dy9sPMeta?.alias || [],
    redirect: errorA34s9Dy9sPMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexOZgz5TcywyMeta?.name ?? "ockto-hash",
    path: indexOZgz5TcywyMeta?.path ?? "/ockto/:hash()",
    meta: indexOZgz5TcywyMeta || {},
    alias: indexOZgz5TcywyMeta?.alias || [],
    redirect: indexOZgz5TcywyMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodewgpz0ebEcPMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodewgpz0ebEcPMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodewgpz0ebEcPMeta || {},
    alias: qrcodewgpz0ebEcPMeta?.alias || [],
    redirect: qrcodewgpz0ebEcPMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesFu61LJ9hP5Meta?.name ?? "ockto-hash-succes",
    path: succesFu61LJ9hP5Meta?.path ?? "/ockto/:hash()/succes",
    meta: succesFu61LJ9hP5Meta || {},
    alias: succesFu61LJ9hP5Meta?.alias || [],
    redirect: succesFu61LJ9hP5Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_933KaLZfVfeHMeta?.name ?? "onboarding-signing_id-token",
    path: _91token_933KaLZfVfeHMeta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_933KaLZfVfeHMeta || {},
    alias: _91token_933KaLZfVfeHMeta?.alias || [],
    redirect: _91token_933KaLZfVfeHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930jMF1Q3UVoMeta?.name ?? "onboarding-slug",
    path: _91slug_930jMF1Q3UVoMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_930jMF1Q3UVoMeta || {},
    alias: _91slug_930jMF1Q3UVoMeta?.alias || [],
    redirect: _91slug_930jMF1Q3UVoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraagswWH6IFPy4Meta?.name ?? "onboarding-aanvraag",
    path: aanvraagswWH6IFPy4Meta?.path ?? "/onboarding/aanvraag",
    meta: aanvraagswWH6IFPy4Meta || {},
    alias: aanvraagswWH6IFPy4Meta?.alias || [],
    redirect: aanvraagswWH6IFPy4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93nV0yUBajuUMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93nV0yUBajuUMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93nV0yUBajuUMeta || {},
    alias: _91experience_93nV0yUBajuUMeta?.alias || [],
    redirect: _91experience_93nV0yUBajuUMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_93F5exsKXiPIMeta?.name ?? "onfido-token",
    path: _91token_93F5exsKXiPIMeta?.path ?? "/onfido/:token()",
    meta: _91token_93F5exsKXiPIMeta || {},
    alias: _91token_93F5exsKXiPIMeta?.alias || [],
    redirect: _91token_93F5exsKXiPIMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93a140bsFunLMeta?.name ?? "passeren-hash",
    path: _91hash_93a140bsFunLMeta?.path ?? "/passeren/:hash()",
    meta: _91hash_93a140bsFunLMeta || {},
    alias: _91hash_93a140bsFunLMeta?.alias || [],
    redirect: _91hash_93a140bsFunLMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexiUU1go5P8GMeta?.name ?? "privacy-verklaring",
    path: indexiUU1go5P8GMeta?.path ?? "/privacy-verklaring",
    meta: indexiUU1go5P8GMeta || {},
    alias: indexiUU1go5P8GMeta?.alias || [],
    redirect: indexiUU1go5P8GMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93aV5IX3RAXoMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93aV5IX3RAXoMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93aV5IX3RAXoMeta || {},
    alias: _91fundKey_93aV5IX3RAXoMeta?.alias || [],
    redirect: _91fundKey_93aV5IX3RAXoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagOEgpMnSe5zMeta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagOEgpMnSe5zMeta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagOEgpMnSe5zMeta || {},
    alias: aanvraagOEgpMnSe5zMeta?.alias || [],
    redirect: aanvraagOEgpMnSe5zMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexHuRMS4W8o1Meta?.name ?? "sso-exchangeToken-onboarding",
    path: indexHuRMS4W8o1Meta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexHuRMS4W8o1Meta || {},
    alias: indexHuRMS4W8o1Meta?.alias || [],
    redirect: indexHuRMS4W8o1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bMAb3tgq0mMeta?.name ?? "sso-token-id",
    path: _91id_93bMAb3tgq0mMeta?.path ?? "/sso/:token()/:id()",
    meta: _91id_93bMAb3tgq0mMeta || {},
    alias: _91id_93bMAb3tgq0mMeta?.alias || [],
    redirect: _91id_93bMAb3tgq0mMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqxVzW8XdBoMeta?.name ?? "sso-token",
    path: indexqxVzW8XdBoMeta?.path ?? "/sso/:token()",
    meta: indexqxVzW8XdBoMeta || {},
    alias: indexqxVzW8XdBoMeta?.alias || [],
    redirect: indexqxVzW8XdBoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93deEVGDK5znMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93deEVGDK5znMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93deEVGDK5znMeta || {},
    alias: _91hash_93deEVGDK5znMeta?.alias || [],
    redirect: _91hash_93deEVGDK5znMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250214070339/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]