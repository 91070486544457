<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { AppButton } from '@mogelijk-technologies/ui-library';

export interface Props {
  error: { statusCode: number, message: string };
}

const props = defineProps<Props>();

const router = useRouter();
const message = ref(props.error.message.length > 0 ? props.error.message : `Pagina niet gevonden:<br/>${router.currentRoute.value.path}`);

onMounted(() => {
  if (router.currentRoute.value.path === '/onfido/') {
    message.value = 'De sessie is verlopen.<br/>Neem contact op met Mogelijk.';
  } else if (router.currentRoute.value.path.startsWith('/onboarding/')) {
    message.value = 'De link is niet meer geldig.<br/>Neem contact op met Mogelijk.';
  }

  router.currentRoute.value.meta.title = 'Oeps...';
});
</script>

<template>
  <div>
    <NuxtLayout name="sidebar">
      <div class="relative flex h-full w-full flex-col lg:flex-row">
        <div class="flex px-4">
          <div class="flex flex-col justify-start">
            <h1 class="relative mb-6 text-[160px] font-semibold leading-none text-blue-300">
              {{ props.error.statusCode }}
            </h1>
            <div class="weight-bold text-3xl font-semibold text-blue-900">
              Oeps...
            </div>
            <div class="mb-6 text-base text-blue-900" v-html="message" />
            <AppButton class="mb-4" @click.prevent="router.push('/aanbod')">
              Ga terug naar actueel aanbod
            </AppButton>
          </div>
        </div>
        <!-- Note: this value is equal to the length of the 404 text. This way it does not get too small -->
        <div class="flex min-h-[361px] w-full grow bg-[url('/404.svg')] bg-contain bg-right-bottom bg-no-repeat" />
      </div>
    </NuxtLayout>
  </div>
</template>

<style scoped lang="css">
:deep(#content) {
  padding-bottom: 0;
  padding-right: 0;
}
</style>
